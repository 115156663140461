import { createTheme } from '@mui/material'
// import { blue, red } from '@mui/material/colors'

//	NOTE:
//	* To add custom definitions in TypeScript, you need to declare a styles module interface - read the 'If you're using TS' bit: https://mui.com/material-ui/customization/palette/#adding-new-colors
//	* Dark mode lookup: https://mui.com/material-ui/customization/dark-mode/
//	* Theme colour explorer: https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette

const typography = {
	fontFamily: [
		'Titillium Web',
		'Urbanist', //  our custom font.
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"'
	].join(',')
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		loginbutton: true,
		editorButton: true
	}
}

const light = createTheme({
	typography,
	palette: {
		mode: 'light',
		primary: {
			main: '#6AA84F',
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: '#FFFFFF',
			contrastText: '#000000'
		},
		background: {
			default: '#cccccc'
		}
	},

	/* Ref: https://mui.com/material-ui/customization/theme-components/ */
	components: {
		MuiButton: {
			variants: [
				{
					props: { variant: 'loginbutton' },
					style: {
						textTransform: 'none',
						backgroundColor: '#ffffff',
						border: '1px solid lightgrey'
					}
				},
				// {
				// 	props: { variant: 'loginbutton', color: 'secondary' },
				// 	style: {
				// 		textTransform: 'none',
				// 		backgroundColor: '#ffffff'
				// 	}
				// },
				{
					props: { variant: 'editorButton' },
					style: {
						'textTransform': 'none',
						'backgroundColor': '#ffffff',
						'border': '1px solid lightgrey',
						'padding': '2px 0',
						'minWidth': '32px',
						'&:hover': {
							background: '#f3f3f3'
						}
					}
				}
			]
		},
		//	TODO: Trying to make the outline of a select transparent
		// MuiSelect: {
		// 	defaultProps: {
		// 		variant: 'outlined'
		// 	},
		// 	styleOverrides: {
		// 		select: {
		// 			'& $notchedOutline': {
		// 				borderColor: 'transparent'
		// 			}
		// 		}
		// 	}
		// },
		MuiCssBaseline: {
			//	Add our grey background image - you can also try background.jpg for a nice colourful image
			styleOverrides: `
				html {
					background-color: transparent;
					position: relative;
				}
				html:before {
					content: ' ';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100vw;
					max-width: 100%;
					height: 100%;
					min-height: 100vh;
					opacity: 0.25;
					background-color: #cccccc;
					background-image: url('/background3.jpg');
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					background-attachment: fixed;
					z-index: -1;
				}
			`
		}
	}

})

const dark = createTheme({
	typography,
	palette: {
		mode: 'dark',
		background: {
			default: '#121212',
			paper: '#333333'
		}
	}
})

const theme = { light, dark }
export default theme
